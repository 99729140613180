import Rest from '@/services/Rest';

/**
 * @typedef {LessonSearchService}
 */
export default class LessonSearchService extends Rest {
  /**
   * @type {String}
   */
  static resource = 'lesson/search';
}
