<template>
  <div class="progress-circular">
    <svg :width="size" :height="size">
      <circle class="background" :cx="radius" :cy="radius" :r="radius - strokeWidth / 2"></circle>
      <circle class="progress" :cx="radius" :cy="radius" :r="radius - strokeWidth / 2" :stroke-dasharray="circumference" :stroke-dashoffset="dashOffset"></circle>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'ProgressCircular',
  props: {
    progress: {
      type: Number,
      default: 0,
    },
    size: {
      type: Number,
      default: 40
    },
    strokeWidth: {
      type: Number,
      default: 10
    },
    strokeColor: {
      type: String,
      default: '#000000'
    }
  },
  computed: {
    radius() {
      return this.size / 2;
    },
    circumference() {
      return 2 * Math.PI * (this.radius - this.strokeWidth / 2);
    },
    dashOffset() {
      return this.circumference * (100 - this.progress) / 100;
    }
  }
};
</script>

<style scoped>
.progress-circular {
  position: relative;
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
}

svg {
  transform: rotate(-90deg);
  background-color: transparent;
}

circle {
  fill: none;
}

.background {
  stroke: var(--background2-v2);
  stroke-width: 3px;
}

.progress {
  stroke: var(--maincolor);
  stroke-linecap: round;
  stroke-width: 3px;
  transition: stroke-dashoffset 0.3s ease-in-out;
}
</style>
